import { createApp } from 'vue'
import App from './App.vue'

import router from './router/router'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';

import "bootstrap-icons/font/bootstrap-icons.css";
import Vuesax from 'vuesax3'

import 'vuesax3/dist/vuesax.css'

 

// import axios from 'axios';
import axios from './assets/js/axios'; // Importa tu configuración de Axios
import VueAxios from 'vue-axios';


import Swal from 'sweetalert2'
// import 'sweetalert2/dist/sweetalert2.min.css';
window.Swal = Swal; //para poder usar globalmente


import moment from 'moment';
moment.locale('es');
window.moment = moment;

 
createApp(App)
.use(router)
.use(VueAxios, axios)
.use(Vuesax)
// .use(VueSweetalert2)
.mount('#app')
