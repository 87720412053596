// src/axios.js
import axios from "axios";

// Crear una instancia de Axios
const instance = axios.create({
  baseURL: process.env.VUE_APP_URL_BACK_ADMISION, // Asegúrate de que esta URL sea la correcta
});

// Variable para evitar múltiples solicitudes de refresh al mismo tiempo
let isRefreshing = false;
let refreshSubscribers = [];

// Función para agregar solicitudes a la cola mientras se refresca el token
const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

// Notificar a todas las solicitudes en la cola cuando el token sea refrescado
const onRefreshed = (newToken) => {
  refreshSubscribers.forEach((cb) => cb(newToken));
  refreshSubscribers = [];
  console.log("Esaaaaaaaaaaa");
};

// Interceptor de solicitudes
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor de respuestas para manejar errores de autenticación
// instance.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;

//     if (error.response && error.response.status === 401) {
//        console.log("Error al refrescar el token:", error.response);

//       if (!isRefreshing) {
//         isRefreshing = true;
//         try {
//           const refreshResponse = await instance.post("/refresh");
//           const newToken = refreshResponse.data.data.token;
//           console.log("Error:", newToken);

//           localStorage.setItem("token", newToken);
//           isRefreshing = false;
//           onRefreshed(newToken);
//           return instance(originalRequest);
//         } catch (refreshError) {
//           console.error(
//             "Error al intentar refrescar el token:",
//             refreshError.response?.data || refreshError
//           );
//           localStorage.removeItem("token");
//           isRefreshing = false;
//           window.location.href = "/login";
//         }
//       } else {
//         return new Promise((resolve) => {
//           subscribeTokenRefresh((newToken) => {
//             originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
//             resolve(instance(originalRequest));
//           });
//         });
//       }
//     }
//     return Promise.reject(error);
//   }
// );

instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      console.log("Interceptor activado, error capturado:", error);
  
      if (error.response && error.response.status === 401) {
        console.log("Error 401 detectado:", error.response);
  
        if (!isRefreshing) {
          isRefreshing = true;
          console.log("Iniciando proceso de refresh...");
  
          try {
            const refreshResponse = await instance.post("/refresh");
            console.log("Respuesta del refresh:", refreshResponse);
  
            const newToken = refreshResponse.data.data.token;
            console.log("Nuevo token obtenido:", newToken);
  
            localStorage.setItem("token", newToken);
            isRefreshing = false;
            onRefreshed(newToken);
            return instance(originalRequest);
          } catch (refreshError) {
            console.error(
              "Error al intentar refrescar el token:",
              refreshError.response?.data || refreshError
            );
            localStorage.removeItem("token");
            isRefreshing = false;
            window.location.href = "/login";
          }
        } 
        
      }
      return Promise.reject(error);
    }
  );
  

export default instance;
